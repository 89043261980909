import { FC } from 'react';

import { Box } from '@mui/material';

import { Styles } from 'types/theme.type';

import LoginFormContainer from './components/LoginFormContainer';

const styles: Styles = {
  box: {
    margin: 'auto',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'common.grey',
    minHeight: 'min-content',
  },
};

const Login: FC = () => (
  <Box sx={styles.box}>
    <LoginFormContainer />
  </Box>
);

export default Login;
