import { FC, useContext, useEffect, useState, useCallback } from 'react';

import ReactCardFlip from 'react-card-flip';
import { useAuth } from 'react-oidc-context';
import { useLocation, useSearchParams, useParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { useGetCurrentUserQuery, useGetFlagsQuery } from 'api/services/account';
import { useGetTransactionToKcLoginFlagsQuery } from 'api/services/auth';
import { Loading } from 'components/Loading';
import TwoFactorAuthSetup from 'components/TwoFactorAuthSetup';
import { AuthContext } from 'contexts/AuthContext';
import { AuthContextType } from 'contexts/AuthContext/types';
import { ApiErrorCodes } from 'enums/apiErrorCodes';
import { AuthParams, MfaConfig } from 'types/auth.type';
import { processServerErrorResponse } from 'utils/errors';

import CancelButton from '../CancelButton';
import LoginForm from '../LoginForm';
import LoginFormContainerWrapper from '../LoginFormContainerWrapper';
import LoginLimitedError from '../LoginLimitedError';
import TwoFactorAuthEnterCodeForm from '../TwoFactorAuthEnterCodeForm';

const cardFlipCardStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const styles = {
  cardFlipContainer: { height: '100%' },
  cardFlipCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardFlipCards: {
    front: cardFlipCardStyles,
    back: cardFlipCardStyles,
  },
};

const LoginFormContainer: FC = () => {
  const [searchParams] = useSearchParams();
  const { module } = useParams();
  const [showOtpForm, setShowOtp] = useState(false);
  const [createMfaData, setCreateMfaData] = useState<MfaConfig | null>(null);
  const [showOtpRestoreCodeForm, setShowOtpRestoreCodeForm] = useState(false);
  const auth = useAuth();
  const location = useLocation();

  const {
    loginState,
    login,
    deleteMfa,
    createMfa,
    verifyOtpCode,
    isAuthenticated,
    isLoading: isLoadingAuthContext,
  } = useContext<AuthContextType>(AuthContext);
  const { data: oidcConfig, isLoading: isLoadingOidcConfig } =
    useGetTransactionToKcLoginFlagsQuery();
  const { isLoading: isLoadingFlags } = useGetFlagsQuery(undefined, {
    skip: !isAuthenticated,
  });
  const errorData = loginState?.error;
  const { isLoading: isLoadingCurrentUser } = useGetCurrentUserQuery(undefined, {
    skip: !isAuthenticated,
  });

  useEffect(() => {
    auth.removeUser();
  }, []);

  useEffect(() => {
    if (loginState?.isMfaAuthConfigured || loginState?.isMfaAuthEnforced) {
      setShowOtp(true);
    }
  }, [loginState]);

  useEffect(() => {
    if (loginState?.isMfaAuthEnforced && !loginState?.isMfaAuthConfigured) {
      createMfa()
        .then(setCreateMfaData)
        .catch((error) => {
          processServerErrorResponse(error);
        });
    }
  }, [loginState, createMfa]);

  const disableCookieSessionAuth = module !== 'admin' && oidcConfig?.disableCookieSessionAuth;

  useEffect(() => {
    if (disableCookieSessionAuth) {
      auth.signinRedirect();
    }
  }, [disableCookieSessionAuth]);

  const cancelOptClickHandler = useCallback(() => setShowOtp(false), []);

  const changeOptVariantClickHandler = useCallback(() => {
    setShowOtpRestoreCodeForm(!showOtpRestoreCodeForm);
  }, [showOtpRestoreCodeForm]);

  const loginFormSubmitHandler = useCallback(
    async ({ email, password }: AuthParams) => {
      await login(email.toLowerCase(), password);
    },
    [login]
  );

  const otpCodeSubmitHandler = useCallback(
    async (code: string) => {
      await verifyOtpCode(code);
    },
    [verifyOtpCode]
  );

  const cancelOptSetupHandler = useCallback(async () => {
    cancelOptClickHandler();
    await deleteMfa();
  }, [cancelOptClickHandler, deleteMfa]);

  const isLoading =
    disableCookieSessionAuth || isLoadingFlags || isLoadingCurrentUser || isLoadingOidcConfig;

  if (isAuthenticated && isLoading) {
    return <Loading />;
  }

  if (isAuthenticated) {
    const redirectTo =
      searchParams.get('next') ??
      searchParams.get('redirectTo') ??
      searchParams.get('redirectUrl') ??
      location.state?.from?.pathname ??
      (process.env.PUBLIC_URL || '/').replace('login', '');
    window.location.href = redirectTo;
    return null;
  }

  const isLimitedAttemptsError = errorData?.code === ApiErrorCodes.LIMIT_EXCEEDED;

  return (
    <ReactCardFlip
      isFlipped={showOtpForm}
      flipDirection="horizontal"
      containerStyle={styles.cardFlipContainer}
      cardStyles={styles.cardFlipCards}
    >
      <LoginForm disabled={isLoadingAuthContext} onSubmit={loginFormSubmitHandler} />

      <Box m="auto" p={0}>
        {loginState?.isMfaAuthConfigured && (
          <ReactCardFlip isFlipped={showOtpRestoreCodeForm} flipDirection="horizontal">
            <LoginFormContainerWrapper>
              <CancelButton onClick={cancelOptClickHandler} />
              <TwoFactorAuthEnterCodeForm
                autoFocus
                disabled={isLoadingAuthContext}
                variant="otp"
                onChangeVariantClick={changeOptVariantClickHandler}
                onSubmit={otpCodeSubmitHandler}
              />
              {isLimitedAttemptsError && <LoginLimitedError error={errorData} />}
            </LoginFormContainerWrapper>
            <LoginFormContainerWrapper>
              <CancelButton onClick={cancelOptClickHandler} />
              <TwoFactorAuthEnterCodeForm
                disabled={isLoadingAuthContext}
                variant="restoreCode"
                onChangeVariantClick={changeOptVariantClickHandler}
                onSubmit={otpCodeSubmitHandler}
              />
            </LoginFormContainerWrapper>
          </ReactCardFlip>
        )}
        {!loginState?.isMfaAuthConfigured && (
          <LoginFormContainerWrapper>
            <CancelButton onClick={cancelOptSetupHandler} />
            <TwoFactorAuthSetup
              otpAuthUrl={createMfaData?.otpAuthUrl}
              otpAuthSecret={createMfaData?.otpAuthSecret}
              onOtpSubmit={otpCodeSubmitHandler}
            />
          </LoginFormContainerWrapper>
        )}
      </Box>
    </ReactCardFlip>
  );
};

export default LoginFormContainer;
