import { oidcUserStoreKey } from '../oidc/oidcUserManagerInstance';

export const getOidcUsedAccessToken = () => {
  const storageUserData = localStorage.getItem(oidcUserStoreKey);
  if (!storageUserData) {
    return undefined;
  }
  const userData = JSON.parse(storageUserData);
  return userData?.access_token;
};
