import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BaseSearchData } from 'types/searches.type';

import { BreadcrumbsState } from './type';

const initialState: BreadcrumbsState = {
  list: [],
};

const slice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setScheduledBreadcrumbs(state, { payload: scheduled }: PayloadAction<BaseSearchData>) {
      if (state.list.find((item) => item.id === scheduled.areaId)) return state;
      return {
        ...state,
        list: [
          ...state.list,
          {
            id: scheduled.areaId,
            area: 'scheduled',
            name: scheduled.name,
            description: scheduled.description,
          },
        ],
      };
    },
  },
});

export const { setScheduledBreadcrumbs } = slice.actions;

export default slice.reducer;
