import { AuthProvider } from '@ornament/ui';
import * as Sentry from '@sentry/react';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import Notification from 'components/Alert';
import { AuthContextProvider } from 'contexts/AuthContext/AuthContextProvider';
import { ThemeProvider } from 'contexts/ThemeContext';
import ErrorPage from 'ErrorPage';
import store from 'store';

import { router } from './router';

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorPage />}>
      <ReduxProvider store={store}>
        <ThemeProvider>
          <AuthProvider>
            <AuthContextProvider>
              <RouterProvider router={router} />
            </AuthContextProvider>
          </AuthProvider>
          <Notification />
        </ThemeProvider>
      </ReduxProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
