// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageHeader-module-header--Kw0Qh{border-bottom:1px solid var(--mui-palette-divider);box-shadow:none;color:"inherit";position:relative}.PageHeader-module-toolbar--qVDZc{display:grid;grid-template-columns:0 1fr auto;min-height:48px}.PageHeader-module-content--uMYuA{display:"flex";flex:1 1;align-items:"center";padding-left:16px;overflow:"hidden"}`, "",{"version":3,"sources":["webpack://./src/components/PageHeader/PageHeader.module.scss"],"names":[],"mappings":"AAAA,iCACE,kDAAA,CACA,eAAA,CACA,eAAA,CACA,iBAAA,CAGF,kCACE,YAAA,CACA,gCAAA,CACA,eAAA,CAGF,kCACE,cAAA,CACA,QAAA,CACA,oBAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":[".header {\n  border-bottom: 1px solid var(--mui-palette-divider);\n  box-shadow: none;\n  color: 'inherit';\n  position: relative;\n}\n\n.toolbar {\n  display: grid;\n  grid-template-columns: 0 1fr auto;\n  min-height: 48px;\n}\n\n.content {\n  display: 'flex';\n  flex: 1 1;\n  align-items: 'center';\n  padding-left: 16px;\n  overflow: 'hidden';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `PageHeader-module-header--Kw0Qh`,
	"toolbar": `PageHeader-module-toolbar--qVDZc`,
	"content": `PageHeader-module-content--uMYuA`
};
export default ___CSS_LOADER_EXPORT___;
