import { FC, PropsWithChildren } from 'react';

import { AppBar, Box, Toolbar } from '@mui/material';

import classes from './PageHeader.module.scss';

export const PageHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <AppBar className={classes.header} color="inherit">
      <Toolbar className={classes.toolbar} disableGutters>
        <Box className={classes.content}>{children}</Box>
      </Toolbar>
    </AppBar>
  );
};
